<template>
  <div class="all">
    <MPHeader1 :tab-index1="tabIndexs1" :changeClass1="changeClass1"/>
    <div class="top">
      <div class="top-title-01">{{ $t('message.about.us') }}</div>
      <div class="top-title-02 ">{{ $t('message.about.starting') }}</div>
      <div class="top-txt-1 color-07"> {{ $t('message.about.initially') }}</div>
    </div>
    <div class="mid">
      <img src="../../assets/20230905/about02.png" class="midimg">
      <div class="m-title">{{ $t('message.about.journey') }}</div>
      <div class="m-txt-1 color-07">
        {{ $t('message.about.zhu_ning') }}
      </div>
    </div>
    <div class="mid">
      <img src="../../assets/20230905/about03.png" class="midimg">
      <div class="m-title">{{ $t('message.about.built') }}</div>
      <p class="m-txt-1 color-07">
        {{ $t('message.about.throughout') }}
      </p>
      <ul class="color-07">
        <li>
          <p class="m-txt-1">{{ $t('message.about.bravely') }}</p>
        </li>
        <li>
          <p class="m-txt-1">{{ $t('message.about.spent') }}</p>
        </li>
        <li>
          <p class="m-txt-1">{{ $t('message.about.redesigned') }}</p>
        </li>
        <li>
          <p class="m-txt-1">{{ $t('message.about.continuously') }}</p>
        </li>
      </ul>
    </div>
    <div class="mid">
      <img src="../../assets/20230905/about04.png" class="midimg">
      <div class="m-txt-1 color-07">
        <span class="color-10">{{ $t('message.about.reliability_0') }}</span>
        {{ $t('message.about.reliability_1') }} 
        <span class="color-10">{{ $t('message.about.reliability_2') }} </span>{{ $t('message.about.reliability_3') }}
        </div>
        <!-- Reliability, Professionalism, and sincerity are the core values of Blucap. We pay attention to
        every detail of our products and patiently polish each aspect to achieve the ultimate best.</div> -->
      <!-- <div class="m-txt-1 color-07">Welcome to Blucap. Where innovation meets the open road.</div> -->
    </div>

    <div class="border01"></div>
    <div class="lx">
      <div class="lx-title">{{ $t('message.about.hq') }}</div>
      <div class="lx-txt-1">{{ $t('message.about.dream') }}</div>
      <div class="lx-txt-2">{{ $t('message.about.support') }}</div>
    </div>
    <div class="lx">
      <div class="lx-title">{{ $t('message.about.hk') }}</div>
      <div class="lx-txt-1">{{ $t('message.about.ug') }}</div>
      <div class="lx-txt-2">{{ $t('message.about.zb') }}</div>
    </div>
    <div class="lx">
      <div class="lx-title">{{ $t('message.about.portland') }}</div>
      <div class="lx-txt-1">{{ $t('message.about.upcoming') }}</div>
      <div class="lx-txt-2">{{ $t('message.about.support') }}</div>
    </div>
    <div class="bot">
      <MPFooter />
    </div>
  </div>
</template>
<script>

import MPHeader1 from "@/components/header/MPHeader04.vue"; //头部
import MPFooter from "@/components/footer/MPFooter04.vue"; //底部
export default {
  components: {
    MPHeader1,
    MPFooter
  },
  data() {
    return {
      changeClass1: 1,
      tabIndexs1: 4, //用来传值给子组件,来显示点击了第几个
      newsList: this.$store.state.newsList,
      isShow: false,
    };
  },
  mounted() {
    //监听页面滚动事件
    // 要防抖
    window.addEventListener("scroll", this.debounce(this.handleScroll, 10));
  },
  methods:{
    // 滑动
    handleScroll: function () {
      let top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (top <60) {
        this.changeClass1 = 1;
      } else {
        this.changeClass1 = 2;
      }
    },
    // 防抖函数
    debounce(fn, wait) {
      let timeout = null;
      return function () {
        if (timeout !== null) clearTimeout(timeout);
        timeout = setTimeout(fn, wait);
      };
    },
  }
};
</script>

<style lang="less" scoped>
.all {
  width: 100%;

  .top {
    background-image: url(../../assets/20230905/Mobile-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 1.7rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    padding-bottom: 0.08rem;
    font-family: League Spartan;
    color: #fff;

    .top-title-01 {
      font-size: 0.42rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .top-title-02 {
      font-size: 0.27rem;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      margin-top: 0.34rem;
    }

    .top-txt-1 {
      font-size: 0.21rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }

  .mid {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    font-family: League Spartan;
    font-style: normal;
    color: #fff;
    margin-bottom: 0.08rem;

    .midimg {
      width: 100%;
    }

    .m-title {
      font-size: 0.27rem;
      font-weight: 500;
      line-height: 120%;
      margin-top: 0.34rem;
    }

    .m-txt-1 {
      font-size: 0.21rem;
      font-weight: 400;
      line-height: 120%;
      margin-top: 0.08rem;
    }
  }

  .border01 {
    width: 3.33rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    height: 0.01rem;
    background-color: #303030;
    margin-top: 0.3rem;
  }

  .lx {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    font-family: League Spartan;
    color: #fff;
    font-style: normal;

    .lx-title {
      margin-top: 0.34rem;
      font-size: 0.21rem;
      font-weight: 600;
      line-height: 120%;
    }

    .lx-txt-1 {
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 120%;
    }

    .lx-txt-2 {
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 120%;
      color: #249EFF;
    }
  }

  .bot {
    border-top: 0.01rem solid #303030;
    margin-top: 0.34rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 0.21rem;
  }

  ul li::marker {
    color: rgba(255, 255, 255, 0.70);
    font-size: 0.21rem;
  }
  .color-07 {
    color: rgba(255, 255, 255, 0.70);
  }
  .color-10{
    color: rgba(255, 255, 255,1);
  }
}
</style>
