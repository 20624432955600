<template>
  <div class="alls">
    <PCHeader1 :tab-index1="tabIndexs1" :changeClass1="changeClass1" />
    <div class="top">
      <div>
        <img src="../../assets/20230905/PC-banner.png" class="topimg" />
      </div>
      <div class="txt-01">{{ $t('message.about.us') }}</div>
      <!-- <div>{{$t('message.author')}}</div> -->
    </div>
    <div class="content">
      <div class="flex row-between row-center">
        <div class="l-c">
          <div class="title-1">{{ $t('message.about.starting') }}</div>
          <div class="txt-1">
            {{ $t('message.about.initially') }}
            </div>
        </div>
        <div>
          <img src="../../assets/20230905/about02.png" class="cimg01">
        </div>
      </div>

      <div class="flex row-between row-center m-t">
        <div>
          <img src="../../assets/20230905/about03.png" class="cimg01">
        </div>
        <div class="l-c">
          <div class="title-1">{{ $t('message.about.journey') }}</div>
          <div class="txt-1">
            {{ $t('message.about.zhu_ning') }}
            </div>
        </div>

      </div>

      <div class="flex row-between row-center m-t">
        <div class="l-c">
          <div class="title-1">{{ $t('message.about.built') }}</div>
          <p class="txt-1">
            {{ $t('message.about.throughout') }}
            
          </p>
          <ul>
            <li>
              <p class="txt-1">
                {{ $t('message.about.bravely') }}
                </p>
            </li>
            <li>
              <p class="txt-1">
                {{ $t('message.about.spent') }}
                </p>
            </li>
          </ul>
        </div>
        <div>
          <img src="../../assets/20230905/about04.png" class="cimg01">
        </div>
      </div>

      <div class="m-t-01">
        <ul>
          <li>
            <p class="txt-1">
              {{ $t('message.about.redesigned') }}
              </p>
          </li>
          <li>
            <p class="txt-1">
              {{ $t('message.about.continuously') }}
              </p>
          </li>
        </ul>
      </div>

      <div class="txt-1 m-t-2 m-t-40">
        <span class="color-10">
          {{ $t('message.about.reliability_0') }}
          </span>{{ $t('message.about.reliability_1') }}
          <span class="color-10">{{ $t('message.about.reliability_2') }}</span> 
          {{ $t('message.about.reliability_3') }}
          
      </div>

    </div>
    <div class="lx">
      <div class="flex row-between row-center">
        <div class="lw-1">
          <div class="ltxt-1">{{ $t('message.about.hq') }}</div>
          <div class="ltxt-2">{{ $t('message.about.dream') }}</div>
          <div class="ltxt-3">{{ $t('message.about.support') }}</div>
        </div>
        <div class="lw-1">
          <div class="ltxt-1">{{ $t('message.about.hk') }}</div>
          <div class="ltxt-2">{{ $t('message.about.ug') }}</div>
          <div class="ltxt-3">{{ $t('message.about.zb') }}</div>
        </div>
      </div>
      <div class="flex row-between row-center lm-t">
        <div class="lw-1">
          <div class="ltxt-1">{{ $t('message.about.portland') }}</div>
          <div class="ltxt-2">{{ $t('message.about.upcoming') }}</div>
          <div class="ltxt-3">{{ $t('message.about.support') }}</div>
        </div>
        <div class="lw-1">

        </div>
      </div>
    </div>
    <div class="foot">
      <PCFooter />
    </div>
  </div>
</template>
<script>
// import $ from "jquery";
import PCHeader1 from "@/components/header/PCHeader04.vue"; //头部
import PCFooter from "@/components/footer/PCFoote01.vue"; //底部
export default {
  components: {
    PCHeader1,
    PCFooter,
  },
  data() {
    return {
      changeClass1: 1,
      tabIndexs1: 4, //用来传值给子组件,来显示点击了第几个
    };
  },
  created() {
    //   这个用来在不同分辨率等比缩放css样式
    // $(function () {
    //   var w = window.screen.width;
    //   var zoom = w / 1920;
    //   $("#app").css({
    //     zoom: zoom,
    //     "-moz-transform": "scale(" + zoom + ")",
    //     "-moz-transform-origin": "top left",
    //   });
    // });
  },
  mounted() {
    //监听页面滚动事件
    // 要防抖
    window.addEventListener("scroll", this.debounce(this.handleScroll, 10));
  },
  methods: {
    // 滑动
    handleScroll: function () {
      let top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (top < 60) {
        this.changeClass1 = 1;
      } else {
        this.changeClass1 = 2;
      }
    },
    // 防抖函数
    debounce(fn, wait) {
      let timeout = null;
      return function () {
        if (timeout !== null) clearTimeout(timeout);
        timeout = setTimeout(fn, wait);
      };
    },
  },
};
</script>
<style lang="less" scoped>
.top {
  width: 100%;
  position: relative;
  // background-image: url(../../assets/20230905/PC-banner.png);
  // background-repeat: no-repeat;
  // background-size: contain;
  // height: 550px;

  // text-align: center;
  .topimg{
    width: 100%;
  }

  .txt-01 {
    
    font-family: League Spartan;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #fff;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 100px;
  }
}

.content {
  width: 970px;
  margin: 0 auto;
  padding-bottom: 40px;
  border-bottom: 1px solid #303030;

  .l-c {
    width: 466px;
    padding-top: 24px;
  }

  .m-t {
    margin-top: 40px;
  }

  .title-1 {
    font-family: League Spartan;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #fff;
  }

  .txt-1 {
    font-family: League Spartan;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
    color: rgba(255, 255, 255, 0.70);
    margin-top: 12px;
  }
  .color-10{
    color: rgba(255, 255, 255,1);
  }
  .m-t-40{
    margin-top: 64px;
  }

  .cimg01 {
    width: 464px;
  }

}

ul {
  list-style-type: disc;
  padding-left: 30px;
}

ul li::marker {
  color: rgba(255, 255, 255, 0.70);
  font-size: 21px;
}

.m-t-01 {
  margin-top: 14px;
}

.m-t-2 {
  margin-top: 64px;
}

.foot {
  border-top: 1px solid #303030;
}

.lx {
  width: 970px;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;

  .lw-1 {
    width: 473px;
    color: #fff;
    font-family: League Spartan;

    .ltxt-1 {
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
    }

    .ltxt-2 {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    .ltxt-3 {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: #249EFF;
      line-height: 120%;
    }
  }

  .lm-t {
    margin-top: 24px;
  }
}
</style>